import request from '@/utils/request'
const url = '/api/index/'

// ----------------评价----------------
export function index(params) {
  return request({
    url: url + 'index',
    method: 'get',
    params: params
  })
}
