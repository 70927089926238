<template>
  <div class="container">
   
    <Header></Header>

    <div class="banner-wrap">
      <video id="myVideo" class="video-js vjs-big-play-centered i-video" autoplay="autoplay" controls="false"  style="width: 100%;">
          <source src="https://api.starmaxintl.com/storage/video/banner.mp4" type="video/mp4">
          
      </video>


    </div>
    <!-- banner-wrap end -->

    <div class="i-main">
      <div class="i-m1">
        <h3 class="i-title text-center">We provide everything you need<br />to renovate your hotel</h3>

        <div class="i-m1-listbox">
          <ul class="i-m1-list">
            <li v-for="(item,k) in model.category" :key="k" class="wow animate__animated animate__fadeInUp" data-wow-delay="0.5s"  data-wow-duration="1s">
              <router-link :to="{name:'category',query:{'category':item.url_name}}">
                <img :src="item.thumb_url" alt="" class="i-list-img1">
                <img :src="item.thumb2_url" alt="" class="i-list-img2">
                <div class="i-m1-text"><span>{{item.category_name}}</span></div>
              </router-link>
            </li>
          </ul>

        </div>
      </div> <!--i-m1 end -->
      
      <div class="i-m2">
        <el-row>
          <el-col :span="12">
            <div class="i-m2-left wow animate__animated animate__fadeInUp"  data-wow-delay="0.5s"  data-wow-duration="1s">
              <img src="https://api.starmaxintl.com/storage/file/20220823/70c9cfa1d36633f9a2560ac3fff8f01313b7ace7.jpg" alt="">
            </div>
          </el-col>
          <el-col :span="12">
            <div class="i-m2-right i-m2-rBig wow animate__animated animate__fadeInUp" data-wow-delay="0.6s"  data-wow-duration="1s">
              <h3 class="i-title">Renovation, the most effective investment in your hotel</h3>
              <h4>Get the most cost effectve renovation solution</h4>
              <div class="i-m2-txt">
                <p>STARMAX provides hotel owners with a unique one-stop hotel renovation solution. Everything we do is designed to make your tomorrow better than today. We want to see your hotel refurbished without the disruptions and limitations of breaks or supply shortages and with the highest quality hotel product for less.</p>
              </div>
              <div class="i-m2-linkBox text-right"><router-link to="/about">About STARMAX ></router-link></div>
            </div>  
          </el-col>
        </el-row>
      </div><!--i-m2 end -->
      <div class="i-m3">
          <div class="i-m3-top">
            <h3 class="i-title text-center">Your hotel supplies advisor</h3>
            <h4>Experts at your fingertips</h4>
            <div class="i-m3-text text-center">Making the right hotel purchasing choices can be difficult. That's where we come in. The STARMAX team has the expertise and skills necessary to help you make practical, efficient, and reliable purchasing choices when it matters most.</div>
          </div>
          <ul class="i-m3-list">
            <li class="wow animate__animated animate__fadeInUp" data-wow-delay="0.2s"  data-wow-duration="1s">
              <a href="#">
                <span class="i-m3-icon">
                  <img src="https://api.starmaxintl.com/storage/file/20220823/2644eb76d03b0baa6da7b1e6f5b8f6f0be6f30b5.png" alt="">
                </span>
                <p>Wider selection of hotel products</p>
              </a>
            </li>
            <li  class="wow animate__animated animate__fadeInUp" data-wow-delay="0.4s"  data-wow-duration="1s">
              <a href="#">
                <span class="i-m3-icon">
                  <img src="https://api.starmaxintl.com/storage/file/20220823/0cafb083face3a9af81c90e0da580d74364e59ba.png" alt="">
                </span>
                <p>Lower hotel renovation costs</p>
              </a>
            </li>
            <li  class="wow animate__animated animate__fadeInUp" data-wow-delay="0.6s"  data-wow-duration="1s">
              <a href="#">
                <span class="i-m3-icon">
                  <img src="https://api.starmaxintl.com/storage/file/20220823/6d0b67b5be4a533f3bd0957798f9acbf4862084c.png" alt="">
                </span>
                <p>Better quality hotel supplies</p>
              </a>
            </li>
          </ul>
      </div>
      <div class="i-m4">
          <img src="https://api.starmaxintl.com/storage/file/20220823/454db5c73a56b43bc6bd3de3f8a20313835c73cc.jpg" alt="" class="i-m4-pic">
          <div class="i-m4-con">
              <div class="i-m4-title" data-aos="fade-up" data-aos-delay="10" data-aos-duration="500" >Make the supply chain your <br/>competitive advantage</div>
              <div class="i-m4-list" ref="myNumbox">
                <ul>
                  <li>
                      <span  data-aos="zoom-in" data-aos-delay="300" data-aos-duration="1500">
                        <animate-number from="0" to="18" duration="1500" ref="myNum" v-show="myNumShow" ></animate-number>
                        <i>+</i>
                      </span>
                      <p>Over 18 years providing hotel supplies and hotel renovation services</p>
                  </li>
				  <li>
                      <span  data-aos="zoom-in" data-aos-delay="300" data-aos-duration="1500">
                        <animate-number from="0" to="200" duration="1500" ref="myNum" v-show="myNumShow" ></animate-number>
                        <i></i>
                      </span>
                      <p>Cooperate with factories in more than 200 countries and regions around the world</p>
                  </li>
				  <li>
                      <span  data-aos="zoom-in" data-aos-delay="300" data-aos-duration="1500">
                        <animate-number from="0" to="1649" duration="1500" ref="myNum" v-show="myNumShow" ></animate-number>
                        <i></i>
                      </span>
                      <p>Over 1000 hotel owners have chosen Starmax to provide them with one-stop hotel solutions and supply services.</p>
                  </li>
                </ul>
                
              </div>
               <!-- <button type="button" @click="startAnimate()"> animate </button> -->
          </div>

      </div>
      <!-- i-m4 end -->

      <div class="i-m2 i-m5">
        <el-row>
          <el-col :span="12">
            <div class="i-m2-left wow animate__animated animate__fadeInUp"  data-wow-delay="0.2s"  data-wow-duration="1.5s">
              <img src="https://api.starmaxintl.com/storage/file/20220823/ebde5855d46cc2f476f2ec6cbb203a96fd5d9eb2.jpg" alt="">
            </div>
          </el-col>
          <el-col :span="12">
            <div class="i-m2-right wow animate__animated animate__fadeInUp"  data-wow-delay="0.4s"  data-wow-duration="1.5s">
              <h3 class="i-title">One-stop hotel supply solutions</h3>
              <div class="i-m5-list">
                <ul>
                  <li><i></i>Design Products</li>
				  <li><i></i>Sourcing materials</li>
				  <li><i></i>Product quality inspection</li>
				  <li><i></i>Goods packaging and crating</li>
				  <li><i></i>Logistics freight tracking</li>
				  <li><i></i>Arrange commissioner installation</li>
				  <li><i></i>Service and warranty</li>
                </ul>
              </div>
              
            </div>  
          </el-col>
        </el-row>
      </div><!--i-m5 end -->

      <div class="i-m2 i-m6">
        <el-row>
          <el-col :span="12">
            <div class="i-m2-right wow animate__animated animate__fadeInUp"  data-wow-delay="0.2s"  data-wow-duration="1.5s">
              <h3 class="i-title">Efficient and convenient purchasing process</h3>
              <div class="i-m5-list">
                <ul>
                  <li><i></i>Selected global quality factories</li>
				  <li><i></i>Provide unique,quality-driven products</li>
				  <li><i></i>Build a vertically integrated supply chain</li>
				  <li><i></i>Saving operational costs</li>
				  <li><i></i>Flexible delivey methods</li>
                </ul>
              </div>
              
            </div>  
          </el-col>
          <el-col :span="12">
            <div class="i-m2-left wow animate__animated animate__fadeInUp"  data-wow-delay="0.4s"  data-wow-duration="1.5s">
              <img src="https://api.starmaxintl.com/storage/file/20220823/2f6150cd9b15fc842f3fbc09d965a061ae2d16e5.jpg" alt="">
            </div>
          </el-col>
          
        </el-row>
      </div><!--i-m5 end -->

      <div class="i-m2 i-m7">
        <el-row>
          <el-col :span="12">
            <div class="i-m2-left wow animate__animated animate__fadeInUp"  data-wow-delay="0.2s"  data-wow-duration="1.5s">
              <img src="https://api.starmaxintl.com/storage/file/20220823/fd7b3616ec2d523fb4d72347d9189c9743a5ae1d.jpg" alt="">
            </div>
          </el-col>
          <el-col :span="12">
            <div class="i-m2-right wow animate__animated animate__fadeInUp"  data-wow-delay="0.4s"  data-wow-duration="1.5s">
              <h3 class="i-title">Professional services team</h3>
              <div class="i-m5-list">
                <ul>
                  <li><i></i>S+++ customer service</li>
				  <li><i></i>Commitment to sustainablilty</li>
				  <li><i></i>Excellent service and support</li>
				  <li><i></i>Professional project management capabilities</li>
				  <li><i></i>Fully integrated services</li>
				  <li><i></i>Collaboration and design assistance</li>
                </ul>
              </div>
              
            </div>  
          </el-col>
        </el-row>
      </div><!--i-m5 end -->

      <div class="i-m8">
        <h3 class="i-title text-center">Our projects</h3>
        <div class="i-m8-con">
          <el-row>
            <el-col :span="8">
              <div class="i-m8-left">
                <div class="i-m2-txt">
                  <p>We serve a wide range of customers in the market, working with clients from independent boutique hotels to well-known brands, including Four Seasons, Marriott, and more.</p>
                </div>
                <div class="i-m2-linkBox "><router-link to="/project">More Projects  ></router-link></div>
              </div>
            </el-col>
            <el-col :span="16">
              <div class="i-m8-right">
               
                <img src="https://api.starmaxintl.com/storage/file/20220823/7287ff4d573e8e65842d2ed0a46ef78a6fb2d017.png" alt="" class="i-m8-r-arrow" @click="m8arrowLink()">
                <div class="swiper-container">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide"  v-for="(item,index) in model.project" :key="index">
                       <router-link :to="{name:'project-detail',query:{'id':item.content_id}}"><img :src="item.thumb_url" /></router-link>
                    </div>
                     
                  </div>
                
                  <div class="swiper-pagination"></div>  
                
                  <div class="swiper-button-prev"></div>
                  <div class="swiper-button-next"></div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>

      
      </div><!--i-m8 end -->
      <div class="i-m9">
        <div class="i-m9-group">
          <div class="i-m9-group-txt i-m9-group-txt-l">
            <p>Our extensive<br>experience<br>speaks for us</p>
          </div>
          <div class="i-m9-group-bar"></div>
        </div>

        <div class="i-m9-con">
          <div class="i-m9-leftArrow" @click="arrowClick('left')">
            <img src="https://api.starmaxintl.com/storage/file/20220823/763d301c90491d17288cdf4ee0e6db4beabc0542.png"   />
          </div>
          <div class="i-m9-body">
            
            <el-carousel  arrow="never" style="height:500px" :autoplay="true" indicator-position="none" ref="cardShow">
              <el-carousel-item v-for="(item,j) in model.evaluate" :key="j">
                <div class="i-m9-item">
                  <div class="i-m9-userImg">
                    <img :src="item.thumb_url" alt="">
                  </div>
                  <div class="i-m9-item-box">
                    <img src="https://api.starmaxintl.com/storage/file/20220823/ce847b9bf57e5c44752ae2eeff44a07ea46b34c7.png" alt="" class="i-m9-yinhao i-m9-yinhao-left">
                    <img src="https://api.starmaxintl.com/storage/file/20220823/d709cfbaeb88d3007bc5aa3ddb483d10ded3f957.png" alt="" class="i-m9-yinhao i-m9-yinhao-right">
                    <p v-html="item.content"></p>
                    <div class="i-m9-item-name"><h3><span>{{item.name}}</span></h3></div>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
            
          </div>
          <div class="i-m9-rightArrow" @click="arrowClick('right')" >
            <img src="https://api.starmaxintl.com/storage/file/20220823/a56097980ecc9e82bc463d20c53f5115c8b61770.png"/>
          </div>
        </div><!--i-m9-con end -->

      </div><!--i-m9 end -->

      <div class="i-m10">

        <div class="i-m9-group">
          <div class="i-m9-group-txt i-m9-group-txt-r">
            <p>Request the<br>latest brochure</p>
          </div>
          <div class="i-m9-group-bar"></div>
        </div>


        <div class="i-m10-listbox">
          <div class="i-m10-list">
            <el-row :gutter="20">
              <el-col :xs="8" :sm="6" :md="4" :lg="4"  v-for="(item,index) in model.download" :key="index" data-index="index">
                <div class="i-m10-item">
                  <div class="i-m10-item-pic" v-proportion="1.2"><img :src="item.img_url" @click="Download(index,item.download_url)"/></div>
                  <!--<p><el-link :href="item.link" data-index="index" :class="{animate : index == download}" @click="Download(index)">Download</el-link></p>-->
				  <p><el-link :href="item.link" data-index="index" :class="{animate : index == download}" @click="Download(index,item.download_url)"><i>Download</i><el-progress :percentage="percentage" :color="customColor"></el-progress></el-link></p>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div><!--i-m10 end -->
	  
      <div class="i-m11" v-show="emailbox">
        <div class="i-m3-top">
          <h3 class="i-title text-center">Discuss your project with us</h3>
          <div class="i-m3-text text-center">Find out how Starmax makes the entire procurement process so easy.</div>
        </div>
		<div class="i-m11-input">
			<el-form ref="form" :model="form" class="cont-form">
				<el-row :gutter="40">
                	<el-col>
						<el-form-item >
							<el-input v-model="form.name" placeholder="Enter your name"></el-input>
						</el-form-item>
					</el-col>
            	</el-row>
				<el-row :gutter="40">
                	<el-col>
						<el-form-item >
							<el-input v-model="form.email" placeholder="Enter your email" ></el-input>
						</el-form-item>
					</el-col>
            	</el-row>
			</el-form>
		</div>
        <div class="i-m11-link">
            <el-button class="wow animate__animated animate__fadeInUp"  data-wow-delay="0.5s"  data-wow-duration="1s" @click="emailSubmit()">
              <span class="icon-email"><img src="https://api.starmaxintl.com/storage/file/20220928/235042b5850e787757f9d4a9198854cf154ed0eb.png" alt=""></span>
              Enter  Your  Email  <i>>></i>  
            </el-button>
        </div>
      </div><!--i-m11 end -->
	  <div class="i-m11" v-show="emailfreeback">
      	<div class="i-m3-bottom">
			<h3 class="i-title text-center">Thank you for connecting!</h3>
			<div class="i-m3-text text-center">You’re now subscribed to receive our newsletter every two weeks.<br/>Hope you'll enjoy it!</div>
		</div>

       </div>

    </div>
    <!-- i-main end  -->
     <Footer></Footer>
  </div>  
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import { index } from '@/api/index'
import { add } from '@/api/email'

import Swiper from "swiper"

export default {
  components: {
    Header,
    Footer,
    Swiper,
},
  data() {
    return {
      bannerHeight:0,
      screenWidth:0,
      isDrag:false,//是否在拖拽
      isClick:false,//是否是单击
	  form: {},
	  emailbox: true,
	  emailfreeback: false,
      swiperInstance: null,
      percentage: 0,
      customColor: '#013656',
	  model:{
	    category: [],
		projecty: [],
	    evaluate: [],
		download: [],
	  },
      download:-1,
      myNumShow:true,
      options: undefined,
      timer: null,
      timer2: null,
      numRun1:'',
      numRun3:'',
      numRun3:'',
      once:true,
    };
  },
  created() {
    this.index();
  },

  computed: {
  },
  updated() {
    var swiperInstance = new Swiper('.swiper-container', {
			  // autoplay: true,
        // loop: true,
        spaceBetween: 20,
        // pagination: {
        //   el: '.swiper-pagination',
        //   clickable: true,
        // },
        slidesPerView: 'auto',
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev",
        // },
	  	});
  },

  mounted() {
      // 首次加载时,初始化高度
      this.screenWidth = window.innerWidth
      this.bannerHeight = 800 / 1920 * this.screenWidth
      // 窗口大小发生改变
      window.onresize = () => {
          this.screenWidth = window.innerWidth
          this.bannerHeight = 800/ 1920 * this.screenWidth
      };
      // document.getElementById("myVideo").play();
      this.getVideo();
     
      

       this.$AOS.init({

          offset: 200,  
          duration: 600,  
          easing: 'ease-in-sine',  
          delay: 100

        });
        new this.$wow.WOW().init({
          boxClass: 'wow',
          animateClass: 'animate__animated',
          offset: 0,
          mobile: true,
          live: true

        });

      window.addEventListener('scroll', this.handleScroll, true)
      
  },

  methods: {
    m8arrowLink(){
       this.$router.push({path:'/project-detail/project-detail'});
    },
    
    Download(index,url){
      this.download = index
       //alert(this.download)
	   if (url) {
         window.open(url);
       }
    
      this.timer = setInterval(() => {
        this.percentage = 100
      }, 1000);
     
      setTimeout(()=>{
        this.download = -1;
        this.percentage = 0;
        clearInterval(this.timer);
        this.timer =null
      },3000);
     

    },

    startAnimate: function () {
      // alert('111')
 
      this.$refs.myNum[0].start()
      this.$refs.myNum[1].start()
      this.$refs.myNum[2].start()
    },



    handleScroll() {
       
       if(this.once == true){
          let scrollTop =  window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop 
          let offsetTop = document.querySelector('.i-m4').offsetTop;
          if ( scrollTop > offsetTop-200 && scrollTop < offsetTop-150 ) { 
            
            // console.log(scrollTop)
            this.startAnimate()
            this.once = false
          } 
          
       }
       
    },
    getVideo() {
      this.$nextTick(() => {
        let myPlayer = this.$video(myVideo, {
            aspectRatio: "1400:787",
            controls: false,           
            autoplay: true,//自动播放属性,muted:静音播放
            muted: true,
            //建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
            preload: "auto",//none不预加载任何数据，直到用户开始播放才开始下载
            // width: "1920",
            // height: "400",
            language: "zh-CN",
            loop: true,//循环
            //poster:""//在视频开始播放之前显示的图像的URL
            // sources: [
            //   {
            //     src: "../../assets/video/banner.mp4",
            //     // src:"//vjs.zencdn.net/v/oceans.mp4",
            //     type: "video/mp4",
            //   },
              
            // ],
            // notSupportedMessage: "此视频暂无法播放，请稍后再试", 
            // controlBar: {
            //   timeDivider: true, // 当前时间和持续时间的分隔符
            //   durationDisplay: true, // 显示持续时间
            //   remainingTimeDisplay: false, // 是否显示剩余时间功能
            //   fullscreenToggle: true, // 是否显示全屏按钮
            // },
        });
      })
    },


    
    handlemouse(e){
        let startTime,endTime;
        let child=document.getElementsByClassName('i-m8-ul')[0];
        let parent=document.getElementsByClassName('i-m8-roll')[0];
        // 使用this.$refs.name获取dom元素,可能会获取到vue组件,他不支持获取元素的宽高以及监听鼠标事件
        let widthDiffer=parent.offsetWidth - child.offsetWidth;
        if(widthDiffer>0){
            //如果grand真实宽度比parent宽度长,那么就不用滑动了,直接返回
            this.isClick=true;
            return
        }
        startTime=new Date().getTime();//获取事件开始时间
        let disx= e.clientX - child.offsetLeft;
        this.isDrag=true;//设置开始拖拽
        parent.onmousemove =(e)=>{
          if(this.isDrag){//拖拽中
              let mouseX=e.clientX; // 鼠标点击的位置
              let x=mouseX-disx;
              // 这里判断是开头和结尾和大盒子对齐
              if(x>0){
                child.style.left='0px'
              }else if(x < -2000){
                child.style.left='-2000px'
              }else{
                child.style.left=x+'px'//设置parent的位置
              }
          }
        }
        parent.onmouseup=(e)=>{
          endTime=new Date().getTime();
          let timeDiffer=endTime-startTime;//获取抬起时间
          this.isDrag=false;
          if(timeDiffer<150){
              //时间间隔小于150,那么就判断为单击事件,这里时间间隔可自由设置
              this.isClick=true;
          }else{
              this.isClick=false;
              //拖拽结束,如果这里parent移出了grand边界,
              parent.onmouseout=(ev)=>{
                this.isDrag=false;
              }
          }
        }
      
    },

    arrowClick(val) {
      if(val === 'right') {
        this.$refs.cardShow.next()
      } else {
        this.$refs.cardShow.prev()
      }
    },
	
	index(){
	  index().then(res => {
        this.model.category=res.data.data.category
		this.model.project=res.data.data.project.list
		this.model.evaluate=res.data.data.evaluate.list
		this.model.download=res.data.data.download.list
      }).catch(() => {})
	},
	emailSubmit(){
      add(this.form).then(res => {
	    if(res.data.code==200){
		   this.emailbox=false
		   this.emailfreeback=true
		   res.data.msg='Submitted successfully!</br>Our sales will contact you within 48 hours.'
		}
		else{
			this.$alert(res.data.msg, {
			  confirmButtonText: 'OK',
			  center:true,
			  dangerouslyUseHTMLString: true
			}).then(()=>{});
		}
	  	
	  }).catch(() => {})
	},

  },
}
</script>

<style>
@import "../../assets/css/index.css";
.i-m11-input{width:25%; margin:30px auto 0 auto;}
</style>
